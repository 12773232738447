
.section-home-spellbee {

    padding:0px 30px;
  
/* nav */
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: inline;
  }
  
  ul a:hover{
    text-decoration: none;
  }
  .navbar_logo{
    float: left;
    height: 65px;
    width: 200px;
  }
  
  
  .nav{
    
     position: fixed;
     top: 0px;
  }
  
  header {
    position: fixed;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    color:white;
    padding: 1em;
    z-index: 10000;
  }
  
  .nav_i{
  float: right;
  padding-top: 10px;
  padding-right: 40px;
  }
  header a{
    color: white;
  }
  header a:hover{
    color: black;
  }
  
  /* form */
  
  form{
    margin-top: 100px;
    margin-bottom: 0px;
    padding:100px;
  }
  .form_single{
    padding-bottom:10px;
  }
  input{
    border: none;
    border-bottom: solid thin black;
    width: 500px;
  }
  button{
    border-radius: 5px;
    background-color: #8363ff;
    color: white;
    border: none;
    cursor: pointer;
    border: solid thin #8363ff;
    padding:5px;
    width: 500px;
    margin-top: 10px;
  }
  button:hover{
    background-color: white;
    color: #8363ff;
  
  }
  body{
    text-align: justify;
    margin-left:150px ;
    margin-right:150px;
  }
  p{
    text-align: justify;
  }
h5 {
    margin-top:20px;
  }
  span{
    font-weight: 580;
  }
  h1{
    margin-top:20px;
  }
  @media only screen and (max-width: 420px) {
    body{
      text-align: justify;
    margin-left:20px ;
    margin-right:20px;
  }
  h1{
    margin-top:20px;
    font-size: 2rem;
  }
  }
  @media only screen and (max-width: 767px) {
    body{
      text-align: justify;
    margin-left:40px ;
    margin-right:40px;
  }
  }

  @media only screen and (min-width: 767px){
    .content{
        margin:0px 150px;
       }
  }

  }
