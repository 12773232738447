.section-home {
   
    .navbar{
        background-color: white !important;
        margin: 0px 150px ;
        .navbar-brand {
            img {
               max-width: 250px;
              }
        }
        .navbar-collapse{
           
            .navbar-nav.mr-auto {
                margin-right: auto;
               
              }
              .nav-item{
                font-size: 16px !important;
                
                font-family: 'Poppins',sans-serif;
              }
              .nav-item a{
                color: white !important;
                
              }
              .form-inline .btn{
                background-image:  var(--button-color); /* Bootstrap success color */
                color: white;
                border: none;
                padding: 8px 40px;
                border-radius: 20px;
                cursor: pointer;
                font-size: 16px !important;
                font-family: 'Poppins',sans-serif;
              }
        }
        
    }

    .home-section {
      
        width: 100%;
        padding: 20px 0;
       
    
            .row{
                margin: 0px 150px ;
                display: flex;
                flex-direction: row;
                justify-content: center; /* Center the items horizontally */
                align-items: center;
                .content{
                    width: 40% ;
                    text-align: left;
                    h2 {
                        font-size:64px;
                        color: #333;  
                        font-family: 'Poppins',sans-serif !important;   
                        font-weight: bold !important; 
                      }
                      p {
                        font-family: 'Poppins',sans-serif !important;  
                /* Adjust based on your design */
                        color: var(dark-grey); /* Adjust based on your design */
                      }
                      .btn-content {
                       
                        background-image:  var(--button-color); /* Bootstrap success color */
                        color: white;
                        border: none;
                        padding: 8px 60px;
                        border-radius: 20px;
                        cursor: pointer;
                        font-weight: 550;
                      }
                }
            
                    .img-container {
                        width: 60%; /* Ensure the container's width adjusts to content */
                        text-align: center; /* Center the image horizontally */
                        margin-top: 20px; /* Add space between content and image */
              
                        img {
                          max-width: 100%; /* Ensure the image doesn't exceed its container's width */
                          max-height: 100%; /* Ensure the image doesn't exceed its container's height */
                          border-radius: 5px; /* Adjust based on your design */
                        }
                      }
              
                    
            
        }



      }
    .customers-section{
        background: var(--lite-purple1) !important;
        padding: 20px;
        .image-container {
           
            img{
                max-width: 220px;
                margin:10px 30px;
            }
            .img3{
                max-width: 150px;
            }
            .img4{
                max-width: 180px;
            }
        }
       
    }
    .about-section{
        width: 100%;
        padding: 20px 0;
        margin: 100px 0px;
       
        
            .row{
                margin: 0px 150px ;
                display: flex;
                flex-direction: row;
                justify-content: center; /* Center the items horizontally */
                align-items: center;
                .content{
                    width:50%;
                    text-align: left;
                    h2 {
                        font-family: 'Poppins',sans-serif;
                        font-size: 56px ;
                        color: #333;  
                        font-weight: bold;
                        span{
                            color:var(--purple)
                        }
                      }
                      p {
                        font-size: 16px !important;
                        text-align: justify;
                /* Adjust based on your design */
                        color: var(dark-grey); /* Adjust based on your design */
                      }
                      .line {
                        width: 100%; /* Adjust as needed */
                        height: 1px; /* Adjust as needed */
                        background-color: grey; /* Adjust the color */
                      }
                      .about_points{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 40px;
                        margin-top:20px;
                        .point{
                                display: flex;
                                flex-direction: row;
                                font-family: 'Poppins',sans-serif;
                                svg{
                                    color: var(--dark-violet);
                                    margin-top: 5px ;
                                    margin-bottom:0px;
                                    top: 0px;
                                }
                                h4{
                                    font-size: 24px !important;
                                    color: var(--dark-violet);
                                    font-weight: bold;
                                    margin-bottom: 0px;
                                    margin-left: 5px;
                                }
                                p{
                                    font-size: 14px;
                                    color: --(dark-grey);
                                     margin-top: 5px ;
                                     margin-bottom:0px;
                                     top: 0px;
                                     margin-left: 5px;
                                }
                                

                        }
                      }
                    
                }
            
                    .img-container {
                        width: 50%; /* Ensure the container's width adjusts to content */
                        text-align: center; /* Center the image horizontally */
                        margin-top: 20px; /* Add space between content and image */
              
                        img {
                          max-width: 100%; /* Ensure the image doesn't exceed its container's width */
                          max-height: 100%; /* Ensure the image doesn't exceed its container's height */
                          border-radius: 5px; /* Adjust based on your design */
                        }
                      }
              
                    
            
        }
    }
    .services-section{
        .container{
            
            display: flex;
            flex-direction: column !important;
            margin: 100px auto;
            gap: 30px;
          
            .row{
                display: flex;
                flex-direction: row !important;
                 justify-content: center;
                 align-items: center;
                 text-align: center;
                 gap: 30px;
              
                .card{
                    border: none;
                    cursor: pointer;
                    background-color: var(--lite-purple4);
                    max-width:350px;
                    height: 300px;
                    padding: 15px;
                    img{
                        max-width: 60px;
                        height: 60px;
                    }
                    h2{
                        font-family: 'Poppins',sans-serif;
                        font-weight: 600 !important;
                        font-size: 40px ;
                    }
                    h3{
                        font-family: 'Poppins',sans-serif;
                        font-weight: 600;
                        font-size: 20px !important;
                        color: var(--dark-violet);
                        text-align: left;
                    }
                    p{
                        max-width:280px ;
                        font-family: 'Poppins',sans-serif;
                        font-size:16px !important;
                        color: var(--dark-grey);
                        text-align: left;
                    }
                }
                .card:hover{
                    
                    box-shadow: rgba(51, 51, 117, 0.2) 0px 7px 29px 0px;;
                }
            }
        }
     
    }
    .expert-section{
        background: var(--button-color);
        padding: 20px;
        .row{
            margin:0px 15px ;
            .content{
                width: 60%;
                h2{
                    font-size: 32px !important;
                    text-align: left;
                    color: white !important;
                }
                p{
                    font-size: 16px !important;
                    text-align: left;
                    color: white;
                }
            }
            .img-container{
                width: 40%;
                img{
                    
                    max-width: 250px;
                }
            }
        }
    }
    .languages-section{
        width: 100%;
        padding: 20px 0;
        background: var(--lite-purple2);
        
            .row{
                margin: 0px 150px ;
                height: 100vh;
                justify-content: center; /* Center the items horizontally */
                align-items: center;
                .content{
                    width:50%;
                    text-align: left;
                    h2 {
                        font-size: 56px ;
                        color: #333; 
                        font-weight: 600 ; 
                        font-family: 'Poppins',sans-serif;
                        span{
                            color:var(--purple);
                           
                            
                        }
                      }
                      p {
                        font-size: 16px !important;
                        text-align: justify;
                /* Adjust based on your design */
                        color: var(dark-grey); /* Adjust based on your design */
                      }
                      .line {
                        width: 100%; /* Adjust as needed */
                        height: 1px; /* Adjust as needed */
                        background-color: grey; /* Adjust the color */
                      }
                      .about_points{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        gap: 40px;
                        margin-top:20px;
                        .point{
                                display: flex;
                                flex-direction: row;
                                font-family: 'Poppins',sans-serif;
                                svg{
                                    color: var(--dark-violet);
                                    margin-top: 5px ;
                                    margin-bottom:0px;
                                    top: 0px;
                                }
                                h4{
                                    font-size: 24px !important;
                                    color: var(--dark-violet);
                                    font-weight: bold;
                                    margin-bottom: 0px;
                                    margin-left: 5px;
                                }
                                p{
                                    font-size: 14px;
                                    color: --(dark-grey);
                                     margin-top: 5px ;
                                     margin-bottom:0px;
                                     top: 0px;
                                     margin-left: 5px;
                                }
                                

                        }
                      }
                    
                }
            
                    .img-container {
                        width: 50%; /* Ensure the container's width adjusts to content */
                        text-align: center; /* Center the image horizontally */
                        margin-top: 20px; /* Add space between content and image */
              
                        img {
                          max-width: 70%; /* Ensure the image doesn't exceed its container's width */
                          max-height: 100%; /* Ensure the image doesn't exceed its container's height */
                          border-radius: 5px; /* Adjust based on your design */
                        }
                      }
              
                    
            
        }
    }
    .pricing-section{
        display: flex  ;
        align-items: center;
        justify-content:  center;
        background: rgb(250, 250, 250);
     
        .container{
            .row{
                display: flex;
                flex-direction: row;
                 margin: 50px auto;
                 justify-content: center;
                 align-items: center;
               
                 h2{
                    font-size: 40px ;
                    font-weight: 500 ;
                    font-family: 'Poppins',sans-serif;
                    color: black !important;
                 }
                 .card-container{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 20px;
                    justify-content: center;
                    margin-top: 50px;
                    margin-left: auto;
                    margin-right: auto;
                   
                    .card{
                        max-width: 400px;
                        background: white;
                        border: none;
                        border-radius: 20px;
                        transition: all 0.5s ease !important;
                        box-shadow: rgba(51, 51, 117, 0.1) 0px 7px 29px 0px !important;
                        .card-body{
                     
                            h3{
                                font-size: 32px !important;
                                font-family: 'Poppins',sans-serif;
                                color:var(--dark-violet);
                                font-weight: 600;
                            }
                            .plan-description{
                                font-size: 16px !important;
                                font-family: 'Poppins',sans-serif;
                                color:var(--dark-grey);
                              
                            }
                            h4{
                                font-size: 32px !important;
                                font-family: 'Poppins',sans-serif;
                                color:var(--purple) !important;
                                font-weight: bold;
                            }
                          
                            .line {
                                width: 100%; /* Adjust as needed */
                                height: 1px; /* Adjust as needed */
                                background-color: var(--dark-grey); /* Adjust the color */
                              }
                           
                            ul {
                                text-align: left !important;
                          
                                li {
                                    background: none;
                                    text-decoration: none;
                                    border: none;
                                    text-align: left !important;
                                    font-family: 'Poppins',sans-serif;
                                    margin-top: 10px;
                                    svg{
                                        margin-right: 5px;
                                    }
                                }
                            }
                            .btn{
                                background: var(--button-color);
                                margin-top:20px ;
                                color:white;
                                padding: 10px 60px;
                                border-radius: 20px;
                                font-family: 'Poppins',sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                            }
                            
                        }
                    }
                    .card:hover {
                        transform: scale(1.02);
                        background: var(--lite-purple5) !important;
                    } 
              
                 }
                
            }
        }
    }
    .map-section{
        .maps{
           
                iframe{
                    width: 100%;
                    height: 400px;
                }
        }
       
    }
    .footer-section {
        padding: 20px;
        background: linear-gradient(to right bottom, #150D3A, #150D3A, #846CE8);
        .contact-details {
            display:flex ;
            flex-direction: row;
            gap: 30px;
            justify-content: space-between;
            margin: 0px 100px;
            align-items: center;
            .contact-company {
                display: flex;
                flex-direction:column;
                justify-content: center;
                gap: 30px;
                align-items: center;
                
                .social-media {
                   display: flex;
                   flex-direction: row;
                   gap: 10px;

                   svg {
                        color: white !important;
                    }
                }
                
            }
            .contact-form-address{
                display:flex ;
                flex-direction: row;
                gap: 50px;
                .contact-form{
                    form{
                        input{
                            width: 300px;
                            height: 40px;
                            font-size:14px !important;
                            font-family: 'Poppins',sans-serif !important;
                            font-size: 14px !important;
                            padding: 10px !important;
                            background-color: var(--lite-purple1);
                            border-radius: 10px;
                            border: none;
                            margin-top: 15px;
                        }
                        textarea{
                            width:300px;
                            padding: 10px !important;
                            font-family: 'Poppins',sans-serif !important;
                            font-size: 14px !important;
                            background-color: var(--lite-purple1);
                            border-radius: 10px;
                            margin-top:15px;
                            border: none;
                        }
                        .submit-btn{
                           color: var(--purple);
                           font-size: 18px;
                           font-weight: 600;
                        }
                        
                    }
                }
                .contact-address{
                    text-align: left;
                    max-width: 200px;
                    margin-top: 15px;
                    h4{
                        font-size: 18px !important;
                        font-family: 'Poppins',sans-serif !important;
                        color: white;
                        text-decoration: underline;
                    }
                    h5{
                        font-size: 16px !important;
                        font-family: 'Poppins',sans-serif !important;
                        color: white;
                        svg{
                          
                            font-size: 14px !important;
                            margin-right: 10px;
                        }
                    }
                    p{     font-size: 14px !important;
                            font-family: 'Poppins',sans-serif !important;
                            color: white;
                      
                    }
                }
            }
        }
        .line {
            width: 100%; /* Adjust as needed */
            height: 1px; /* Adjust as needed */
            background-color: rgb(238, 238, 238); /* Adjust the color */
            margin-top: 30px;
          }
          .links-copyrights{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            margin-left:100px;
            margin-right: 100px;
            .links{
                    p{
                        display: flex;
                        flex-direction: row;
                        gap: 10px;
                        color: white;
                        font-family: 'Poppins',sans-serif !important;
                        font-size: 14px !important;
                        font-weight: normal !important;
                    }
            }
            .copyrights{
                p{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    color: white;
                    font-family: 'Poppins',sans-serif !important;
                    font-size: 14px !important;
                    font-weight: normal !important;
                    span{
                        font-weight: 600;
                    }
                }
            }
          }
    }
    
}
@media (max-width: 1440px) {


     
       
        .home-section {
           .row{
            margin: 0px 50px !important;
                .content{
                    width: 40%;
                 h2{
                    font-size: 48px !important;
                 }

                }
                .img-container {
                    width: 60%;
                }

           }
        
         }

         .pricing-section{

            .row{
                display: flex;
                flex-direction: row;
                 margin: 20px auto;
                 justify-content: center;
                 align-items: center;
                 .card-container{
                    .card{
                        max-width: 320px !important;
                    }
                }
             }
         }
         .languages-section{
            .row{
                margin: 0px 50px !important;
              
                .content{
                    
                    h2{
                        font-size: 48px !important;
                    }
                }
            }
         }
    
    }




@media (max-width: 768px) {

   
    /* Stack columns vertically on small screens */
        .navbar{
            background-color: white !important;
            margin: 0px 50px !important;
        }
        .home-section {
            
                margin-top: 0px !important; 
                .row {
                    margin: 0px 50px !important;
                    flex-direction: column !important;
                .content{
                        width: 100% !important ;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        
                        align-items: center !important;

                    h2{
                        text-align: center;
                    }
                    p{
                        text-align: center;
                    }

                }
                
                .img-container {
                    text-align:center  !important;
                    width: 100% !important;
                }
            }
        }
        .about-section{
                .row{
                    margin: 0px 50px !important;
                    flex-direction: column !important;
                    .content{
                        width: 100% !important;
                    }
                    .img-container{
                        width: 100% !important;
                        margin-top: 50px !important;
                    }
                }
        }

        .services-section{
            .container{
            
                
            }

        }

        .expert-section{
            .container{
                margin: 0px 0px !important;
                .row{
                    margin: 0px 0px !important;
                    .content{
                        width:60% !important;
                    }
                    .img-container{
                        text-align: right !important;
                        width: 40% !important;
                        img{
                            text-align: right !important;
                        }
                    }

                }
            }
        }

        .languages-section{
          
                .row{
                    margin: 0px 50px !important;
                  
                    .content{
                        width: 100% !important;
                        h2{
                            font-size: 48px !important;
                        }
                    }
                    .img-container{
                        margin-top: 0px;
                        width: 100% !important;

                    }
                }
            
        }

         .footer-section{
            .contact-details{
                display:flex ;
                flex-direction: column !important;
                margin: 0px 50px !important;
               
            }
            .links-copyrights{
              flex-direction: column;
              margin-left:50px !important;
              margin-right: 50px !important;
            }
         }
}

@media (max-width: 512px) {
    .footer-section{
        .contact-details{
            display:flex ;
            flex-direction: column !important;
            margin: 0px 10px !important;
           .contact-form-address{
            flex-direction: column !important;
            .contact-address{
                display: flex;
                flex-direction: column;
                margin: 0px auto;
                text-align: center !important;
            }
           }
        }
        .links-copyrights{
        
          flex-direction: column;
          margin-left:10px !important;
          margin-right:10px !important;
        }
     }

     .languages-section{
          
        .row{
            margin: 0px 10px !important;
             gap: 10px;
            .content{
                width: 100% !important;
                h2{
                    font-size: 32px !important;
                    font-weight:600 !important;
                    
                }
            }
            .img-container{
                margin-top: -50px !important;
                
                width: 100% !important;

            }
        }

    }
    .expert-section{
        padding: 0px !important;
        .container{
            padding: 0px !important;
            margin: 0px 0px !important;
            .row{
                margin: 0px 0px !important;
                flex-direction: column;
                .content{
                   
                    width:100% !important;
                    padding:20px 20px;
                    h2{
                        text-align:center !important ;
                    }
                    p{
                        text-align:center !important ;
                    }
                }
                .img-container{
                    justify-content: center;
                    align-items: center;
                    text-align: center !important;
                    width: 100% !important;
                    padding-bottom: 20px;
                    img{
                        text-align: right !important;
                    }
                }

            }
        }
    }

    .services-section{
        .container{
            margin: 20px auto !important;

            .row{
             
                    h2{
                        font-size: 32px !important;
                        font-weight: 500px;
                    }
                
            }
        }
    }
    .about-section{
        margin: 20px 0px !important;
        .row{
            margin: 0px 10px !important;
            .content{
                h2{
                    text-align: left;
                    font-size: 32px !important;
                    font-weight: 700 !important;
                }
                .about_points{
                    flex-direction: column !important;
                    gap: 10px !important;
                }
            }
        }
    }
    .customers-section{
        .image-container{
            .img3,.img4{
               
                max-width: 110px !important;
            }
            img{
                max-width: 110px !important;
            }
        }
    }
    .home-section{
       
        .row{
            margin: 0px 10px !important;
            .content{
                width: 100% !important;
                h2{
                    font-size: 42px !important;
                }
                p{
                    font-size: 16px !important;
                }
            }
            .img-container{
                width: 100% !important;
            }
        }
    }
    .pricing-section{
        background: rgb(248, 245, 255) !important;
        .container{
            .row{
                h2{
                    font-size: 36px !important;
                    font-weight: bold !important;

                }
            }
        }
    }
    .navbar{
        background-color: white !important;
        margin: 0px 20px !important ;
    }
}