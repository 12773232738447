/* index.css */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif; /* Apply Poppins font to the body */
  background-color: white; /* Set background color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


:root {
  --black: #000000;
  --white: #FFFFFF;
  --button-color: linear-gradient(to right, #D870FD, #B164FF,#8263FF);
  --lite-purple1:#F5F3FF;
  --lite-purple2:linear-gradient(to bottom, rgba(238, 222, 255, 0.12),#ffffff);;
  --lite-purple3:#F1EDFF;
  --lite-purple4:rgba(245,243,255,0.55);
  --lite-purple5:#dad1ff;
  --lite-black: #121212;
  --purple:#8263FF;
  --dark-violet:#170A4F;
  --dark-grey: #273B4A;
  --grey: #9c9c9c;
  --lite-grey: #E7E7E7;
  --blue: #00D8D6;
  --red: #FF1F47;
}

h1{

}
h2{
  font-size: 72px !important;
  font-weight: bold !important;
  color: var(--dark-violet) !important;
}

h3{
  font-size: 48px !important;

}
h4{
  font-size: 32px !important ;
}
h5{

  font-size: 24px !important;
}
p{
  font-size: 24px !important ;
}

@media (max-width: 1440px) {


  h2{
    font-size: 48px !important;
    
    color: var(--dark-violet) !important;
  }
  
  h3{
    font-size: 48px !important;
  
  }
  h4{
    font-size: 32px !important ;
  }
  h5{
  
    font-size: 24px !important;
  }
  p{
    font-size:16px !important ;
  }
  
}

